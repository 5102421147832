import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <p>PV Charging is an iOS app to sync your PV system with your EV charging</p>
        <p>App under construction, stay tuned</p>
        <img src="pic1.jpeg" alt="" width="500"/>
      
      </header>
      <body>
      <h3> &copy; 2024 PV Charging</h3>
      </body>
    </div>
  );
}

export default App;
